import styled from 'styled-components/macro';
import { VFlex } from 'components/Atoms/Layouts/Flex';
import { Image } from 'components/Blocks/Image/Image';

const GlobeImage = () => (
  <FixedBgContainer justify={'center'}>
    <Image
      alt={'ds4a globe'}
      height={'540px'}
      src={
        'https://storage.googleapis.com/training-internal-assets/Faded_Globe.png'
      }
    />
  </FixedBgContainer>
);

export { GlobeImage };

const FixedBgContainer = styled(VFlex)`
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  width: 100vw;
`;
