import { Auth0Provider as Auth0Configs } from '@auth0/auth0-react';

const Auth0Provider = ({ children }) => {
  return (
    <Auth0Configs
      audience={process.env.REACT_APP_AUTH0_API_AUDIENCE}
      cacheLocation={'localstorage'}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      redirectUri={'' + window.location.origin}
      useRefreshTokens
    >
      {children}
    </Auth0Configs>
  );
};

export { Auth0Provider };
