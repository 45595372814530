import styled from 'styled-components/macro';

const OBJECT_FIT = {
  cover: 'cover',
  contain: 'contain',
  fill: 'fill',
  none: 'none',
  scaleDown: 'scale-down'
};

const setBorderRadius = borderRadius => {
  switch (borderRadius) {
    case 'rounded':
      return '50%';
    case '3xl':
      return '1.5rem';
    case '2xl':
      return '1rem';
    case 'xl':
      return '0.75rem';
    case 'lg':
      return '0.5rem';
    case 'md':
      return '0.375rem';
    case 'sm':
      return '0.125rem';
    case 'none':
      return '0';
  }
};

export const Image = styled.img`
  border-radius: ${({ borderRadius }) => setBorderRadius(borderRadius)};
  height: ${({ height }) => height};
  object-fit: ${({ objectFit }) => OBJECT_FIT[objectFit]};
  object-position: ${({ objectPosition }) => objectPosition};
  width: ${({ width }) => width};
`;
