import styled from 'styled-components/macro';

const DIRECTIONS = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  baseline: 'baseline',
  stretch: 'stretch'
};

const Flex = styled.div`
  align-items: ${({ align }) => align && DIRECTIONS[align]};
  display: flex;
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  gap: ${({ gap }) => gap && `${gap}px`};
  justify-content: ${({ justify }) => justify && DIRECTIONS[justify]};
  width: 100%;
`;

export const HFlex = styled(Flex)``;

export const VFlex = styled(Flex)`
  flex-direction: column;
`;

export const CFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const FlexItem = styled.div`
  align-self: ${({ alignSelf }) => alignSelf && DIRECTIONS[alignSelf]};
  flex: ${({ flex }) => flex && `${flex}`};
`;
