import styled from 'styled-components/macro';

const setUnderline = underline => {
  switch (underline) {
    case 'none':
      return `text-decoration: none;`;
    case 'hover':
      return `
        text-decoration: none;
        &::after {
          background: currentColor;
          content: '';
          display: block;
          height: 1px;
          transition: 300ms;
          width: 0px;
        }
        &:hover::after {
          width: 100%;
        }
      `;
    case 'always':
      return 'text-decoration: underline currentColor;';
    default:
      return 'text-decoration: none;';
  }
};

export const Link = styled.a`
  align-items: center;
  border: 1px solid transparent;
  color: ${({ $uiTheme }) => `var(--color-${$uiTheme}-default)`};
  cursor: pointer;
  display: inline-block;
  font-size: ${({ size }) => (size === 'sm' ? '12px' : '14px')};
  font-weight: var(--weight-bold);
  letter-spacing: 1px;
  text-transform: ${({ $textTransform }) => $textTransform};
  transition: all 200ms ease-out;
  width: fit-content;
  ${({ underline }) => setUnderline(underline)};
  ${({ $isListItem }) =>
    $isListItem &&
    `
      &::before {
        background: currentColor;
        border-radius: 100px;
        content: '';
        display: inline-block;
        height: 6px;
        margin-right: 8px;
        position: relative;
        top: -2px;
        width: 6px;
  }
  `}
`;
