import { useState } from 'react';
import PropTypes from 'prop-types';
import { Image as StyledImage } from 'components/Atoms/Elements/Image';

const Image = ({
  borderRadius,
  fallback,
  fallbackSrc,
  height,
  objectFit,
  src,
  width,
  ...htmlProps
}) => {
  const [source, setSource] = useState(src);
  const styledProps = {
    borderRadius,
    objectFit,
    height,
    width
  };

  if (!source && fallback) {
    return fallback;
  }

  return (
    <StyledImage
      onError={() => {
        if (fallback) {
          setSource(null);
        } else {
          setSource(fallbackSrc);
        }
      }}
      src={source}
      {...styledProps}
      {...htmlProps}
    />
  );
};

Image.propTypes = {
  borderRadius: PropTypes.oneOf([
    'rounded',
    '3xl',
    '2xl',
    'xl',
    'lg',
    'md',
    'sm',
    'none'
  ]),
  /**
   * Fallback element to show if image is loading or image fails.
   * @type React.ReactElement
   */
  fallback: PropTypes.element,
  /*
   * Fallback image src to show if image is loading or image fails.
   * Note 🚨: We recommend you use a local image
   */
  fallbackSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  srcSet: PropTypes.string,
  alt: PropTypes.string,
  /*
   * The loading attribute specifies whether a browser should load an image immediately or to defer loading of off-screen images until for example the user scrolls near them.
   */
  loading: PropTypes.oneOf(['lazy', 'eager', 'auto']),
  /*
   * How the image to fit within its bounds. It maps to css `object-fit` property.
   */
  objectFit: PropTypes.oneOf(['cover', 'contain', 'fill', 'none', 'scaleDown']),
  // Other native HTML props
  htmlProps: PropTypes.object
};

Image.defaultProps = {
  alt: '',
  borderRadius: 'none',
  loading: 'lazy',
  objectFit: 'cover',
  src: '',
  srcSet: ''
};

export { Image };
