import { SVG } from 'components/Atoms/Elements/SVG';

const externalLinkIcon = (
  <SVG>
    <path d={'M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'}></path>
    <polyline points={'15 3 21 3 21 9'}></polyline>
    <line x1={'10'} x2={'21'} y1={'14'} y2={'3'}></line>
  </SVG>
);

export { externalLinkIcon };
