import styled from 'styled-components/macro';
import { CenteredSpinner } from 'components/Blocks/ContentLoader/CenteredSpinner';

const LoadingOverlay = ({ isLoading, offset, size = 32, uiTheme }) => {
  return (
    <>
      {isLoading && (
        <LoadingContainer position={offset}>
          <OpacityOverlay />
          <CenteredSpinner size={size} uiTheme={uiTheme} />
        </LoadingContainer>
      )}
    </>
  );
};

export { LoadingOverlay };

const OpacityOverlay = styled.div`
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  position: absolute;
  width: 100%;
`;

const LoadingContainer = styled.div`
  height: 100%;
  margin-left: ${({ position }) => position && `${position.x}px`};
  margin-top: ${({ position }) => position && `${position.y}px`};
  position: absolute;
  width: 100%;
  z-index: var(--z-index-overbase);
`;
