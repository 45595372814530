import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Link as StyledLink } from 'components/Atoms/Elements/Link';
import { Container } from 'components/Atoms/Layouts/Container';
import { externalLinkIcon } from 'assets/externalLinkIcon';

/*
  Links are accessible elements used primarily for navigation. 
  This component is styled to resemble a hyperlink and semantically renders an <a>.
*/
const Link = ({
  children,
  href,
  isExternal,
  isIconHidden,
  isListItem,
  size,
  textTransform,
  to,
  uiTheme,
  underline,
  ...props
}) => {
  return (
    <StyledLink
      $isListItem={isListItem}
      $textTransform={textTransform}
      $uiTheme={uiTheme}
      as={isExternal ? 'a' : ReactRouterLink}
      href={href}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      size={size}
      target={isExternal ? '_blank' : undefined}
      to={to}
      underline={underline}
      {...props}
    >
      {children}
      {isExternal && !isIconHidden && (
        <IconContainer>{externalLinkIcon}</IconContainer>
      )}
    </StyledLink>
  );
};

export { Link };

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  /**
   * If true, the link will be rendered as an `<a>` tag.
   * If false, the link will be rendered as a `<Link>` tag.
   */
  isExternal: PropTypes.bool,
  /**
   * If true, the icon will be hidden.
   * If false, the icon will be shown.
   */
  isIconHidden: PropTypes.bool,
  /**
   * If true, the link will be rendered as part of a bulleted list.
   */
  isListItem: PropTypes.bool,
  /**
   * The size of the link.
   */
  size: PropTypes.oneOf(['sm', 'md']),
  textTransform: PropTypes.oneOf([
    'capitalize',
    'lowercase',
    'uppercase',
    'none'
  ]),
  // If you pass a routing library like React Router, you can pass the 'to' prop to render a link to a route.
  to: PropTypes.string,
  uiTheme: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'info',
    'warning',
    'white',
    'neutral'
  ]),
  /**
   * The underline style of the link.
   * 'none' will remove the underline.
   * 'hover' will add a hover underline.
   * 'always' will add an underline.
   */
  underline: PropTypes.oneOf(['none', 'hover', 'always'])
};

Link.defaultProps = {
  children: null,
  href: '',
  isExternal: false,
  isIconHidden: false,
  isListItem: false,
  size: 'md',
  textTransform: 'capitalize',
  to: '',
  uiTheme: 'info',
  underline: 'hover'
};

// TODO: [6718]: SVG component & assets are not accessible to pass props.
const IconContainer = styled(Container)`
  display: inline-block;
  margin-left: 4px;
  vertical-align: bottom;
  svg {
    height: 18px;
    width: 18px;
  }
`;
