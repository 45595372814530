import styled from 'styled-components/macro';
import { Container } from 'components/Atoms/Layouts/Container';
import { HFlex, VFlex } from 'components/Atoms/Layouts/Flex';
import { H5 } from 'components/Atoms/Typography/Headings';
import { Description } from 'components/Atoms/Typography/Texts';
import { GlobeImage } from 'components/Blocks/GlobeImage/GlobeImage';
import { Link } from 'components/Blocks/Link/Link';
import { unexpectedErrorImage } from 'assets/unexpectedErrorImage';

const FallbackComponent = () => {
  return (
    <LayoutContainer align={'center'} justify={'center'}>
      <VFlex align={'center'} gap={16} justify={'center'}>
        <ErrorText>{'Something went wrong!'}</ErrorText>
        <ErrorImageContainer fullWidth>
          {unexpectedErrorImage}
        </ErrorImageContainer>
        <InfoContainer gap={'8'} justify={'center'}>
          <HFlex gap={'8'} justify={'center'}>
            <ExtendDescription>
              {"Contact your program's operations member or "}
            </ExtendDescription>
            <Link
              href={'mailto:owen@correlation-one.com'}
              isExternal
              isIconHidden
              textTransform={'lowercase'}
            >
              {'owen@correlation-one.com'}
            </Link>
          </HFlex>
          <HFlex gap={'8'} justify={'center'}>
            <ExtendDescription>{'Go back to the '}</ExtendDescription>
            <Link as={'a'} href={'/'}>
              {'Home page'}
            </Link>
          </HFlex>
        </InfoContainer>
      </VFlex>
      <GlobeImage />
    </LayoutContainer>
  );
};

export { FallbackComponent };

const LayoutContainer = styled(VFlex)`
  background: var(--color-primary-gradient);
  color: var(--color-white-default);
  height: 100%;
  min-height: 100vh;
  padding: 64px 48px 48px;
  text-align: center;
`;

const ErrorText = styled(H5)`
  color: var(--color-white-default);
  z-index: var(--z-index-underbase);
`;

const ExtendDescription = styled(Description)`
  color: var(--color-white-default);
  font-weight: var(--weight-bold);
`;

const InfoContainer = styled(VFlex)`
  z-index: var(--z-index-underbase);
`;

const ErrorImageContainer = styled(Container)`
  max-width: 500px;
  width: 100%;
  z-index: var(--z-index-underbase);
`;
