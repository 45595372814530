import styled from 'styled-components/macro';

// Any updates should come directly from design system:
// https://correlation-one.zeroheight.com/styleguide/s/71490/p/9532e5-typography

export const BaseText = styled.p.attrs(({ uiTheme, textAlign }) => ({
  color: uiTheme
    ? `var(--color-${uiTheme}-default)`
    : `var(--color-primary-default)`,
  textAlign: textAlign
}))`
  color: ${({ color }) => color};
  margin: 0;
  padding: 0;
  text-align: ${({ textAlign }) => textAlign};
  white-space: ${({ whiteSpace }) => whiteSpace};
`;

export const Subtitle1 = styled(BaseText)`
  font-family: var(--font-roboto);
  font-size: 16px;
  font-weight: var(--weight-bold);
  letter-spacing: 0.15px;
  line-height: 22px;
`;

export const Subtitle2 = styled(BaseText)`
  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: var(--weight-bold);
  letter-spacing: 0.1px;
`;

export const Body1 = styled(BaseText)`
  color: var(--color-neutral-42);
  font-family: var(--font-roboto);
  font-size: 16px;
  font-weight: var(--weight-regular);
  letter-spacing: 0.5px;
  line-height: 22px;
`;

export const Body2 = styled(BaseText)`
  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: ${({ isBold }) =>
    isBold ? 'var(--weight-bold)' : 'var(--weight-regular)'};
  letter-spacing: 0.25px;
  line-height: 20px;
`;

export const Caption = styled(BaseText)`
  font-family: var(--font-roboto);
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

export const Overline = styled(BaseText)`
  font-family: var(--font-roboto-condensed);
  font-size: 12px;
  font-weight: var(--weight-bold);
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

// TODO: Remove when no more refs in code
export const Description = styled(BaseText)`
  color: var(--color-primary-light);
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 18px;
`;

export const Danger = styled(Caption)`
  color: var(--color-danger-default);
`;
