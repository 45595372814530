import styled from 'styled-components/macro';

// Any updates should come directly from design system:
// https://correlation-one.zeroheight.com/styleguide/s/71490/p/9532e5-typography

export const H1 = styled.h1.attrs(({ uiTheme, textAlign, textTransform }) => ({
  color: uiTheme
    ? `var(--color-${uiTheme}-default)`
    : `var(--color-primary-default)`,
  textAlign: textAlign,
  textTransform: textTransform
}))`
  color: ${({ color }) => color};
  font-family: var(--font-roboto);
  font-size: 96px;
  font-weight: var(--weight-light);
  letter-spacing: -1.5px;
  margin: 0;
  padding: 0;
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textTransform }) => textTransform};
`;

export const H2 = styled(H1).attrs({
  as: 'h2'
})`
  font-family: var(--font-roboto);
  font-size: 60px;
  font-weight: var(--weight-light);
  letter-spacing: -0.5px;
`;

export const H3 = styled(H1).attrs({
  as: 'h3'
})`
  font-family: var(--font-roboto);
  font-size: 48px;
  font-weight: var(--weight-regular);
  letter-spacing: 0px;
`;

export const H4 = styled(H1).attrs({
  as: 'h4'
})`
  font-family: var(--font-roboto-condensed);
  font-size: 34px;
  font-weight: var(--weight-regular);
  letter-spacing: 0.5px;
`;

export const H5 = styled(H1).attrs({
  as: 'h5'
})`
  font-family: var(--font-roboto-condensed);
  font-size: 24px;
  font-weight: var(--weight-bold);
  letter-spacing: 0.25px;
`;

export const H6 = styled(H1).attrs({
  as: 'h6'
})`
  font-family: var(--font-roboto-condensed);
  font-size: 20px;
  font-weight: var(--weight-bold);
  letter-spacing: 1px;
`;
