import PropTypes from 'prop-types';
import { Spinner } from 'components/Atoms/Elements/Spinner';
import { LoadingOverlay } from 'components/Blocks/ContentLoader/LoadingOverlay';
import { LoadingWrapper } from 'components/Blocks/ContentLoader/LoadingWrapper';

const ContentLoader = ({
  isLoading,
  children,
  size = 32,
  uiTheme,
  as,
  offset
}) => {
  switch (as) {
    case 'overlay':
      return (
        <LoadingOverlay
          isLoading={isLoading}
          offset={offset}
          size={size}
          uiTheme={uiTheme}
        />
      );
    case 'wrapper':
      return (
        <LoadingWrapper isLoading={isLoading} size={size} uiTheme={uiTheme}>
          {children}
        </LoadingWrapper>
      );
    case 'spinner':
      return isLoading ? <Spinner size={size} uiTheme={uiTheme} /> : null;
    default:
      return <Spinner size={size} uiTheme={uiTheme} />;
  }
};

export { ContentLoader };

ContentLoader.propTypes = {
  /** Defines how the content loading should work.
   * If set to `overlay`, it will render an opacity overlay and a spinner.
   * If set to `wrapper`, it will replace the children with a spinner.
   * If set to `spinner`, it will only show a spinner.
   * It defaults to `spinner`.
   */
  as: PropTypes.oneOf(['overlay', 'wrapper', 'spinner']).isRequired,
  /** To show or hide the spinner. If `true` appear as loading. */
  isLoading: PropTypes.bool.isRequired,
  /** To define the size of the loading spinner. It defaults to 32 */
  size: PropTypes.number,
  /** If provided, the spinner will use this theme as color.
   * It defaults to white
   */
  uiTheme: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'success',
    'warning',
    'info'
  ]),
  /** If the component is rendered as `overlay`, this sets an offset on the
   * general content loader.
   */
  offset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  /** If the component is rendered as `wrapper`, the children will be replaced
   * by the spinner when loading.
   */
  children: PropTypes.node
};

ContentLoader.defaultProps = {
  size: 32,
  as: 'spinner',
  uiTheme: 'secondary'
};
