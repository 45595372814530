import styled from 'styled-components/macro';

const SVG = styled.svg.attrs(props => ({
  xmlns: 'http://www.w3.org/2000/svg',
  width: props?.width || '24',
  height: props?.height || '24',
  fill: 'none',
  viewBox: `0 0 ${props?.width > 24 ? props?.width : 24} ${
    props?.height > 24 ? props?.height : 24
  }`,
  strokeWidth: '2',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  stroke: 'currentColor'
}))`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export { SVG };
