import { Suspense } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ContentLoader } from 'components/Blocks/ContentLoader/ContentLoader';
import { lazyWithRetry } from 'utils/helpers/lazyWithRetry';

// Lazy load different apps
const AuthedApp = lazyWithRetry(
  () => import('pages/App/AuthedApp'),
  'AuthedApp'
);
const UnauthedApp = lazyWithRetry(
  () => import('pages/App/UnauthedApp'),
  'UnauthedApp'
);

const App = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Suspense
      fallback={
        <ContentLoader
          as={'wrapper'}
          isLoading
          size={32}
          uiTheme={'secondary'}
        />
      }
    >
      {isAuthenticated ? <AuthedApp /> : <UnauthedApp />}
    </Suspense>
  );
};

export { App };
