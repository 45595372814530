import styled, { keyframes } from 'styled-components/macro';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div.attrs({ ['data-testid']: 'spinner' })`
  animation: ${rotate360} 500ms linear infinite;
  background: transparent;
  border: 2px solid
    ${({ uiTheme }) =>
      uiTheme
        ? `var(--color-${uiTheme}-default)`
        : `var(--color-white-default)`};
  border-radius: 100px;
  border-right: 2px solid transparent;
  height: ${({ size }) => (size ? `${size}px` : '24px')};
  position: relative;
  width: ${({ size }) => (size ? `${size}px` : '24px')};
  &:after {
    background: transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    border-radius: 100px;
    border-right: 2px solid
      ${({ uiTheme }) =>
        uiTheme
          ? `var(--color-${uiTheme}-default)`
          : `var(--color-white-default)`};
    border-top: 2px solid transparent;
    content: '';
    height: calc(100% + 4px);
    left: -2px;
    opacity: 0.3;
    position: absolute;
    top: -2px;
    width: calc(100% + 4px);
  }
`;
