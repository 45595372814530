/* eslint-disable no-restricted-imports */
import { StrictMode } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { App } from 'pages/App/App';
import { Auth0Provider } from 'pages/AuthPages/Auth0Provider';
import { FallbackComponent } from 'components/Blocks/ErrorBoundary/FallbackComponent';
import 'styles/normalize.css';
import { GlobalStyles } from 'styles/GlobalStyles';

// eslint-disable-next-line
console.log(
  'You are using Correlation One ' +
    process.env.REACT_APP_NAME +
    ' version: ' +
    process.env.REACT_APP_VERSION
);

const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://b987018dd95c45d5af5ae46e6dc1c3d7@o1174160.ingest.sentry.io/6270020',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: process.env.NODE_ENV === 'production',
  release: process.env.REACT_APP_VERSION,
  tunnel: process.env.REACT_APP_API_URL + 'sentry',
  tracesSampleRate: 0.5
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID
  });

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <GlobalStyles />
          <Sentry.ErrorBoundary fallback={FallbackComponent}>
            <Auth0Provider>
              <LDProvider>
                <App />
              </LDProvider>
            </Auth0Provider>
          </Sentry.ErrorBoundary>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </StrictMode>
  );
})();
