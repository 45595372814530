import { lazy } from 'react';

/**
 * Method to retry loading a resource if it fails the first time.
 * We refresh the page to have the latest version of the app and then retry
 * importing the component.
 * If it fails a second time, we throw the error.
 * @param componentImport - import() method pointing to the desired component
 * @param componentName - name of the component that is being imported
 */
export const lazyWithRetry = (componentImport, componentName) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );
    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return { default: component[componentName] };
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the app.
        // Refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the app.
      // The app crashes and we raise the error.
      throw error;
    }
  });
