import styled, { css } from 'styled-components/macro';

const absoluteCenteredPosition = css`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Container = styled.div`
  border-radius: ${({ rounded }) => (rounded ? '4px' : '0px')};
  ${({ p }) => (p ? `padding: ${p}px;` : '')}
  ${({ pt }) => (pt ? `padding-top: ${pt}px;` : '')}
  ${({ pb }) => (pb ? `padding-bottom: ${pb}px;` : '')}
  ${({ pl }) => (pl ? `padding-left: ${pl}px;` : '')}
  ${({ pr }) => (pr ? `padding-right: ${pr}px;` : '')}
  ${({ py }) =>
    py
      ? `padding-top: ${py}px; 
  padding-bottom: ${py}px;`
      : ''}
  ${({ px }) =>
    px
      ? `padding-left: ${px}px; 
  padding-right: ${px}px;`
      : ''}
  ${({ b }) => (b ? `border: ${b}px solid var(--color-neutral-84);` : '')}
  ${({ bt }) =>
    bt ? `border-top: ${bt}px solid var(--color-neutral-84);` : ''}
  ${({ bb }) =>
    bb ? `border-bottom: ${bb}px solid var(--color-neutral-84);` : ''}
  ${({ bl }) =>
    bl ? `border-left: ${bl}px solid var(--color-neutral-84);` : ''}
  ${({ br }) =>
    br ? `border-right: ${br}px solid var(--color-neutral-84);` : ''}
  ${({ by }) =>
    by
      ? `border-tob: ${by}px solid var(--color-neutral-84); 
  border-bottom: ${by}px solid var(--color-neutral-84);`
      : ''}
  ${({ bx }) =>
    bx
      ? `border-left: ${bx}px solid var(--color-neutral-84); 
  border-right: ${bx}px solid var(--color-neutral-84);`
      : ''}
  margin: ${({ auto }) => (auto ? '0 auto' : '')};
  max-width: ${({ maxWidth }) => (maxWidth ? `var(--media-${maxWidth});` : '')};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}` : '')};
  min-width: ${({ minWidth }) => (minWidth ? `var(--media-${minWidth});` : '')};
  position: ${({ relativePosition }) => relativePosition && 'relative'};
  ${({ absoluteCenter }) => absoluteCenter && absoluteCenteredPosition}
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};
  z-index: ${({ zIndex }) => zIndex ?? zIndex};
`;
