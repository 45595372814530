import { CenteredSpinner } from 'components/Blocks/ContentLoader/CenteredSpinner';

const LoadingWrapper = ({ isLoading, children, size = 32, uiTheme }) => {
  return !isLoading ? (
    children
  ) : (
    <CenteredSpinner size={size} uiTheme={uiTheme} />
  );
};

export { LoadingWrapper };
