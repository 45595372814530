import { Spinner } from 'components/Atoms/Elements/Spinner';
import { Container } from 'components/Atoms/Layouts/Container';

const CenteredSpinner = ({ size, uiTheme }) => {
  return (
    <Container absoluteCenter>
      <Spinner size={size} uiTheme={uiTheme} />
    </Container>
  );
};

export { CenteredSpinner };
