import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyles = createGlobalStyle`
    /* Please see https://zeroheight.com/8b70dbc1a/p/0408d4-colors for reference */
    :root {
        /* Roboto Fonts */
        --font-roboto: "Roboto";
        --font-roboto-condensed: "Roboto Condensed";

        /* Font Weights */
        --weight-light: 300;
        --weight-regular: 400;
        --weight-medium: 500;
        --weight-bold: 700;
        --weight-black: 900;

        /* Primary Colors */
        --color-primary-dark: #000016; /* 10% darker */
        --color-primary-default: #2A2A3b;
        --color-primary-light: #535264; /* 10% lighter */
        --color-primary-translucent: rgba(42, 42, 59, 0.15);
        --color-primary-gradient: linear-gradient(0deg, rgb(42, 42, 59) 0%, rgb(24, 24, 39) 100%);

        /* Secondary Colors */
        --color-secondary-dark: #5078b9;
        --color-secondary-default: #7494C7;
        --color-secondary-light: #99b0d6;
        --color-secondary-translucent: rgba(116, 148, 199, 0.15);

        /* System Colors */
        --color-success-dark: #437e0c;
        --color-success-default: #5DA51A;
        --color-success-light: #74db14;
        --color-success-translucent: rgba(93, 165, 26, 0.15);

        --color-danger-dark: #e61935;
        --color-danger-default: #EC465B;
        --color-danger-light: #f07585;
        --color-danger-translucent: rgba(236, 70, 91, 0.15);

        --color-warning-dark: #a66817;
        --color-warning-default: #D3851D;
        --color-warning-light: #e59c3e;
        --color-warning-translucent: rgba(211, 133, 29, 0.15);

        --color-info-dark: #2896f6;
        --color-info-default: #59ADF8;
        --color-info-light: #89c5fa;
        --color-info-translucent: rgba(89, 173, 248, 0.15);

        /* Neutral Colors */
        --color-neutral-default: #6B6B6B;
        --color-neutral-96: #f5f5f5;
        --color-neutral-95: #F2F1F1;
        --color-neutral-90: #E8E8E8;
        --color-neutral-84: #D6D6D6;
        --color-neutral-53: #888888;
        --color-neutral-42: #6B6B6B;
        --color-neutral-black: #000000;
        --color-white-default: #FFFFFF;
    
        /* Media Queries */
        --media-xs: 200px;
        --media-sm: 480px;
        --media-md: 768px;
        --media-lg: 1024px;
        --media-xl: 1280px;

        /* Stacking contexts */
        --z-index-underbase: 100;
        --z-index-base: 200;
        --z-index-overbase: 300;
        --z-index-navbar: 400;
        --z-index-dropdown: 500;
        --z-index-popover: 600;
        --z-index-tooltip: 700;
        --z-index-modal: 800;
        --z-index-error: 900;
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    body {
        background: var(--color-neutrals-white);
        font-family: var(--font-roboto), -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
        margin: 0;
        padding:0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}`;

export { GlobalStyles };
